import React from 'react'
import { Section } from '../../section'
import pulseImg from '../../../images/healfy-pulse.png'

const DigitalDemo = ({ theme = 'light' }) => (
  <Section theme={theme} legacy>
    <div>
      <div className="news-kicker">
        <h4>2020-05-12</h4>
        <div className="icon-pulse">
          <img src={pulseImg} alt="healfy-pulse" />
        </div>
      </div>
      <h1>Nu är 30 minuters digital demo av Healfy möjligt</h1>
      <p>
        Healfy är en digital tjänst för hälso- och sjukvård på distans. Den är trygg, välbeprövad och ett utmärkt komplement till befintlig
        hälso- och sjukvård. Och toppaktuell för kommuner och landsting just nu.
      </p>
      <p>
        Med Healfy kan man till exempel mäta blodtryck, vikt, blodsocker och blodvärde. Mätningarna görs med hjälp av trådlös utrustning och
        en surfplatta. Enkelt och portabelt!
      </p>
      <p>
        Tjänsten minskar behovet av fysiska besök och därmed risken för smittspridning på Sveriges vårdcentraler och sjukhus. Det innebär
        inte minst att riskgrupper, till exempel inom äldreomsorgen slipper utsätta sig för onödig fara men kan ha en fortsatt trygg sjuk-
        och hälsovård på distans.
      </p>
      <p>
        Den enskilde individen kan ha koll på sin hälsa från det egna hemmet och vårdpersonal kan enkelt nyttja den mobila lösningen på
        äldreboenden eller i hemtjänsten.
      </p>
      <h3>Varför Healfy?</h3>
      <div className="row">
        <div className="col-md-6">
          <ul className="check-list">
            <li>Säkert &amp; tillgängligt</li>
            <li>Kostnadseffektiv</li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="check-list">
            <li>Går snabbt att implementera</li>
            <li>Långsiktig och flexibel lösning</li>
          </ul>
        </div>
      </div>
      <h3>Intresserad av en 30 minuters demo?</h3>
      <p>Vill du veta mer erbjuder vi en 30-minuters demo via videolänk där vi demonstrerar hur Healfy fungerar.</p>
      <h3>Kontakta</h3>
      <div className="contact">
        <p className="contact__heading">Björn Sundqvist</p>
        <div className="contact__links">
          <a href="mailto:bjorn.sundqvist@nhiab.com">bjorn.sundqvist@nhiab.com</a>
          <a href="tel:+460733415331">+46 07 33 41 53 31</a>
        </div>
      </div>
      <p>
        <em>
          Healfy är en tjänst från{' '}
          <a href="https://www.nhiab.com/om-nhi" target="_blank" rel="noopener noreferrer">
            NHI AB
          </a>{' '}
          som erbjuder flexibla, kostnadseffektiva och standardiserade virtuella hälsorum till offentliga och privata kunder.
        </em>
      </p>
    </div>
  </Section>
)

export default DigitalDemo
