import React from 'react'
import { Section } from '../../section'
import pulseImg from '../../../images/healfy-pulse.png'

const Version21 = ({ theme = 'light' }) => (
  <Section theme={theme} legacy>
    <div>
      <div className="news-kicker">
        <h4>2019-09-17</h4>
        <div className="icon-pulse">
          <img src={pulseImg} alt="healfy-pulse" />
        </div>
      </div>
      <h1>Version 2.1 släpptes</h1>
      <h3>Beskrivning</h3>
      <p>
        Andra produktionsversionen av Healfy. Uppdateringen innehåller många spännande funktioner som ger förbättrad användning av Healfy
        inom hälso- och sjukvården. Användarna kan utföra ett antal olika mätningar som kan sparas i systemet. Funktion för videosamtal har
        tillkommit för kommunikation mellan vårdgivare och patient. Användarna kan lägga till foto i mätningssessionen, vilket kan bidra
        till att påskynda diagnos. Förbättrade användargränssnitt och funktioner tillgodoser bättre systemanvändarnas behov.
      </p>
      <h3>Nya funktioner</h3>
      <div className="row">
        <div className="col-md-6">
          <ul className="check-list">
            <li>Ta foto och lägga till beskrivning till dessa under mätningssessionen</li>
            <li>
              Ställa in mätgränser i administrationspanelen som informerar användare när deras mätresultat ligger utanför inställda
              intervall{' '}
            </li>
            <li>Vårdgivare kan spara mätningar på uppdrag av patienter </li>
            <li>Utökad mätningshistorik </li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="check-list">
            <li>Administrations panel</li>
            <li>Videochatt</li>
            <li>Kioskläge i Android-app</li>
            <li>Nya gränssnitt för Android-appen och MyPages webbplats</li>
          </ul>
        </div>
      </div>
    </div>
  </Section>
)

export default Version21
