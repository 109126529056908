import React from 'react'
import { Section } from '../../section'
import pulseImg from '../../../images/healfy-pulse.png'

const Version20 = ({ theme = 'dark' }) => (
  <Section theme={theme} legacy>
    <div>
      <div className="news-kicker">
        <h4>2019-05-15</h4>
        <div className="icon-pulse">
          <img src={pulseImg} alt="healfy-pulse" />
        </div>
      </div>
      <h1>Version 2.0 släpptes</h1>
      <h3>Beskrivning</h3>
      <p>
        Första produktionsversionen av Healfy. Plattformen består av ett API lager, en Andriod applikation och två webbplatser – Ett
        hälsovårdgivaregränssnitt och Mina sidor. Plattformen har funktionalitet för att genomföra hälsoundersökningar på distans.
      </p>
      <h3>Funktioner</h3>
      <div className="row">
        <div className="col-md-6">
          <ul className="check-list">
            <li>Genomföra och lagra medicinska tester: Blodtryck, Vikt, Blodsocker (glukos), Blodvärde (haemoglobin)</li>
            <li>Möjlighet för hälsovårdgivare och användare att se testresultat</li>
            <li>Hälsoenkäter</li>
            <li>Distribuera och ta del av hälsoriktlinjer</li>
            <li>Genomföra video samtal mellan hälsovårdgivare och användare</li>
            <li>BankID inloggning</li>
            <li>Användarhantering</li>
            <li>Söka användare</li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="check-list">
            <li>Registrera användare</li>
            <li>Audit logs</li>
            <li>Säkerhet – Databaskryptering Enbart HTTPS kommunikation</li>
            <li>Support för A&amp;D Medical UA-767PBT-C, A&amp;D Medical UC-351PBT-CI, HemoCue Glucose 201+, HemoCue HB 201+ mätenheter</li>
            <li>Support för Samsung Galaxy Tab Active 2 med Android version 7.1 eller högre</li>
            <li>Support för Google Chrome</li>
            <li>Video och text instruktioner</li>
            <li>CE märkt</li>
          </ul>
        </div>
      </div>
    </div>
  </Section>
)

export default Version20
