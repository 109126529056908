import React from 'react'
import Layout from '../components/layout'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import Version20 from '../components/news/version-2-0'
import Version21 from '../components/news/version-2-1'
import DigitalDemo from '../components/news/digital-demo'

const NewsPage = ({ data }) => (
  <Layout>
    <Header external={true} />
    <DigitalDemo theme="light" />
    <Version21 theme="dark" />
    <Version20 theme="light" />
    <Footer theme="dark" page="news" />
  </Layout>
)

export default NewsPage
